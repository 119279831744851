import React from 'react'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';

export const SidebarData = [
    {
        title: "home",
        icon: <HomeOutlinedIcon id="icon"/>,
        link: "/"
    },
    {
        title: "carousel",
        icon: <BurstModeOutlinedIcon id="icon"/>,
        link: "/slides"
    },
    {
        title: "info",
        icon: <InfoOutlinedIcon id="icon"/>,
        link: "/about"
    },
    {
        title: "scroll_images",
        icon: <ViewDayOutlinedIcon id="icon"/>,
        link: "/scroll"
    }
]

