import React from 'react'

import Install1 from './images/install/installation1.jpg';
import Install2 from './images/install/installation2.jpg';
import Install3 from './images/install/installation3.jpg';
import Install4 from './images/install/installation4.jpg';
import Install5 from './images/install/installation5.jpg';
import Install6 from './images/install/installation6.jpg';
import Install7 from './images/install/installation7.jpg';
import Install8 from './images/install/installation8.jpg';
import Install9 from './images/install/installation9.jpg';

import IanClose1 from './images/ian1/ianClose1.jpg';
import IanClose2 from './images/ian1/ianClose2.jpg';
import IanClose3 from './images/ian1/ianClose3.jpg';
import IanClose4 from './images/ian1/ianClose4.jpg';

import IanFloor1 from './images/ian2/ianFloor1.jpg';
import IanFloor2 from './images/ian2/ianFloor2.jpg';
import IanFloor3 from './images/ian2/ianFloor3.jpg';
import IanFloor4 from './images/ian2/ianFloor4.jpg';

import Mike1 from './images/mike/mike1.jpg';
import Mike2 from './images/mike/mike2.jpg';
import Mike3 from './images/mike/mike3.jpg';
import Mike4 from './images/mike/mike4.jpg';
import Mike5 from './images/mike/mike5.jpg';
import Mike6 from './images/mike/mike6.jpg';
import Mike7 from './images/mike/mike7.jpg';
import Mike8 from './images/mike/mike8.jpg';
import Mike9 from './images/mike/mike9.jpg';
import Mike10 from './images/mike/mike10.jpg';
import Mike11 from './images/mike/mike11.jpg';
import Mike12 from './images/mike/mike12.jpg';
import Mike13 from './images/mike/mike13.jpg';
import Mike14 from './images/mike/mike14.jpg';
import Mike15 from './images/mike/mike15.jpg';
import Mike16 from './images/mike/mike16.jpg';
import Mike17 from './images/mike/mike17.jpg';
import Mike18 from './images/mike/mike18.jpg';
import Mike19 from './images/mike/mike19.jpg';
import Mike20 from './images/mike/mike20.jpg';
import Mike21 from './images/mike/mike21.jpg';
import Mike22 from './images/mike/mike22.jpg';
import Mike23 from './images/mike/mike23.jpg';

const imageData = [
    {
        key: "0",
        artist: "",
        title: "Michael Rey and Ian Trout",
        year: "",
        medium: "September 24 - November 5, 2022",
        dims: "@ Radical Patience",
        info: "installation",
        pic: <img src={Install1} id="image" alt="artwork"/>,
        src: {Install1},
        alt: "artwork",
        link: "/slides#0"
    },
    {
        id: "1",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install2} id="image" alt="artwork"/>,
        src: {Install2},
        alt: "artwork",
        link: "/slides#1"
    },
    {
        id: "2",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install3} id="image" alt="artwork"/>,
        src: {Install3},
        alt: "artwork",
        link: "/slides#2"
    },
    {
        id: "3",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install4} id="image" alt="artwork"/>,
        src: {Install4},
        alt: "artwork",
        link: "/slides#3"
    },
    {
        id: "4",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install5} id="image" alt="artwork"/>,
        src: {Install5},
        alt: "artwork",
        link: "/slides#4"
    },
    {
        id: "5",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install6} id="image" alt="artwork"/>,
        src: {Install6},
        alt: "artwork",
        link: "/slides#5"
    },
    {
        id: "6",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install7} id="image" alt="artwork"/>,
        src: {Install7},
        alt: "artwork",
        link: "/slides#6"
    },
    {
        id: "7",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install8} id="image" alt="artwork"/>,
        src: {Install8},
        alt: "artwork",
        link: "/slides#7"
    },
    {
        id: "8",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "installation",
        pic: <img src={Install9} id="image" alt="artwork"/>,
        src: {Install9},
        alt: "artwork",
        link: "/slides#8"
    },
    {
        id: "9",
        artist: "Ian Trout",
        title: "Second Amendment Zombie",
        year: ", 2022",
        medium: "Graphite on paper",
        dims: "22.25 x 15 inches",
        info: "frame close",
        pic: <img src={IanClose1} id="image" alt="artwork"/>,
        src: {IanClose1},
        alt: "artwork",
        link: "/slides#9"
    },
    {
        id: "10",
        artist: "Ian Trout",
        title: "Second Amendment Zombie",
        year: ", 2022",
        medium: "Graphite on paper",
        dims: "22.25 x 15 inches",
        info: "frame close",
        pic: <img src={IanClose2} id="image" alt="artwork"/>,
        src: {IanClose2},
        alt: "artwork",
        link: "/slides#10"
    },
    {
        id: "11",
        artist: "Ian Trout",
        title: "Second Amendment Zombie",
        year: ", 2022",
        medium: "Graphite on paper",
        dims: "22.25 x 15 inches",
        info: "frame close",
        pic: <img src={IanClose3} id="image" alt="artwork"/>,
        src: {IanClose3},
        alt: "artwork",
        link: "/slides#11"
    },
    {
        id: "12",
        artist: "Ian Trout",
        title: "Second Amendment Zombie",
        year: ", 2022",
        medium: "Graphite on paper",
        dims: "22.25 x 15 inches",
        info: "frame close",
        pic: <img src={IanClose4} id="image" alt="artwork"/>,
        src: {IanClose4},
        alt: "artwork",
        link: "/slides#12"
    },
    {
        id: "13",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "frame floor",
        pic: <img src={IanFloor3} id="image" alt="artwork"/>,
        src: {IanFloor3},
        alt: "artwork",
        link: "/slides#13"
    },
    {
        id: "14",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "frame floor",
        pic: <img src={IanFloor2} id="image" alt="artwork"/>,
        src: {IanFloor2},
        alt: "artwork",
        link: "/slides#14"
    },
    {
        id: "15",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "frame floor",
        pic: <img src={IanFloor1} id="image" alt="artwork"/>,
        src: {IanFloor1},
        alt: "artwork",
        link: "/slides#15"
    },
    {
        id: "16",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "frame floor",
        pic: <img src={IanFloor4} id="image" alt="artwork"/>,
        src: {IanFloor4},
        alt: "artwork",
        link: "/slides#16"
    },
    {
        id: "17",
        artist: "Michael Rey",
        title: "PXOPXO-CHAIR",
        year: ", 2022",
        medium: "Wood, enamel paint and artist's upholstery",
        dims: "84 x 76 x 46 inches",
        info: "sculpture",
        pic: <img src={Mike1} id="image" alt="artwork"/>,
        src: {Mike1},
        alt: "artwork",
        link: "/slides#17"
    },
    {
        id: "18",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike2} id="image" alt="artwork"/>,
        src: {Mike2},
        alt: "artwork",
        link: "/slides#18"
    },
    {
        id: "19",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike3} id="image" alt="artwork"/>,
        src: {Mike3},
        alt: "artwork",
        link: "/slides#19"
    },
    {
        id: "20",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike4} id="image" alt="artwork"/>,
        src: {Mike4},
        alt: "artwork",
        link: "/slides#20"
    },
    {
        id: "21",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike5} id="image" alt="artwork"/>,
        src: {Mike5},
        alt: "artwork",
        link: "/slides#21"
    },
    {
        id: "22",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike6} id="image" alt="artwork"/>,
        src: {Mike6},
        alt: "artwork",
        link: "/slides#22"
    },
    {
        id: "23",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike7} id="image" alt="artwork"/>,
        src: {Mike7},
        alt: "artwork",
        link: "/slides#23"
    },
    {
        id: "24",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike8} id="image" alt="artwork"/>,
        src: {Mike8},
        alt: "artwork",
        link: "/slides#24"
    },
    {
        id: "25",
        artist: "Michael Rey",
        title: "KRONJAV—CHAIR",
        year: ", 2022",
        medium: "Wood, enamel paint and artist's upholstery",
        dims: "64.5 x 48 x 28.5 inches",
        info: "sculpture",
        pic: <img src={Mike9} id="image" alt="artwork"/>,
        src: {Mike9},
        alt: "artwork",
        link: "/slides#25"
    },
    {
        id: "26",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike10} id="image" alt="artwork"/>,
        src: {Mike10},
        alt: "artwork",
        link: "/slides#26"
    },
    {
        id: "27",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike11} id="image" alt="artwork"/>,
        src: {Mike11},
        alt: "artwork",
        link: "/slides#27"
    },
    {
        id: "28",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike12} id="image" alt="artwork"/>,
        src: {Mike12},
        alt: "artwork",
        link: "/slides#28"
    },
    {
        id: "29",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike13} id="image" alt="artwork"/>,
        src: {Mike13},
        alt: "artwork",
        link: "/slides#29"
    },
    {
        id: "30",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike14} id="image" alt="artwork"/>,
        src: {Mike14},
        alt: "artwork",
        link: "/slides#30"
    },
    {
        id: "31",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike15} id="image" alt="artwork"/>,
        src: {Mike15},
        alt: "artwork",
        link: "/slides#31"
    },
    {
        id: "32",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture", 
        pic: <img src={Mike16} id="image" alt="artwork"/>,
        src: {Mike16},
        alt: "artwork",
        link: "/slides#32"
    },
    {
        id: "33",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike17} id="image" alt="artwork"/>,
        src: {Mike17},
        alt: "artwork",
        link: "/slides#33"
    },
    {
        id: "34",
        artist: "Michael Rey",
        title: "OZZISTE-CHAIR",
        year: ", 2022",
        medium: "Wood, enamel paint and artist's upholstery",
        dims: "86 x 32 x 32 inches",
        info: "sculpture",
        pic: <img src={Mike18} id="image" alt="artwork"/>,
        src: {Mike18},
        alt: "artwork",
        link: "/slides#34"
    },
    {
        id: "35",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike19} id="image" alt="artwork"/>,
        src: {Mike19},
        alt: "artwork",
        link: "/slides#35"
    },
    {
        id: "36",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike20} id="image" alt="artwork"/>,
        src: {Mike20},
        alt: "artwork",
        link: "/slides#36"
    },
    {
        id: "37",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike21} id="image" alt="artwork"/>,
        src: {Mike21},
        alt: "artwork",
        link: "/slides#37"
    },
    {
        id: "38",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike22} id="image" alt="artwork"/>,
        src: {Mike22},
        alt: "artwork",
        link: "/slides#38"
    },
    {
        id: "39",
        artist: "",
        title: "",
        year: "",
        medium: "",
        dims: "",
        info: "sculpture",
        pic: <img src={Mike23} id="image" alt="artwork"/>,
        src: {Mike23},
        alt: "artwork",
        link: "/slides#39"
    }
]

export default imageData;
