import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './containers/Home';
import About from './containers/About';
import Error from './containers/Error';
import SharedLayout from './containers/SharedLayout';
import Scroll from './containers/Scroll';
import Slides from './containers/Slides';

import './App.css';

function App() {
  
  return (
    <BrowserRouter>
        <Routes>

            <Route path='/' element={<SharedLayout />}>
                
                <Route index element={<Home />} />
                    
                <Route path='about' element={<About />} />

                <Route path='scroll' element={<Scroll />} />
                
                <Route path='slides' element={<Slides />}></Route>
                
                <Route path='*' element={<Error />} />

            </Route>

        </Routes>
    </BrowserRouter>
  );
}

export default App;

