import FlyerImage from '../images/flyerImage.jpg';
import { Link } from 'react-router-dom';
import {SidebarData} from "../components/SidebarData";

const Home = () => {
    return (
    <section className='section'>

        <div className="current">
            <div id = "currentSpace5"></div>

            <img id="emailImage1" src={FlyerImage} alt="flyer"/>

            <div id="homeText">
               
                <div id = "currentSpace3"></div>

                <div id="info">
                    <p id= "homeNames">
                    Michael Rey and Ian Trout
                    </p>

                    <div id = "currentSpace2"></div>
                    
                    <p id="homeOther">
                    September 24 - November 5, 2022
                    </p>

                    <p id="homeOther">
                    @ Radical Patience
                    </p>

                    <div id = "currentSpace3"></div>
                </div>

                <div className="infoHome">
                    <div>
                        <Link to={SidebarData[2].link}>
                            {SidebarData[2].icon}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    </section>
    );
  };
  
  export default Home;
    