import React from "react";
import "../App.css";

import Carousel, { CarouselItem } from "../components/Carousel";
import imageData from "../imageData.js";
import { useEffect } from "react";

function Slides() {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    var getBody = document.getElementsByTagName("body")[0];

    getBody.style = "overflow: hidden";

    //console.log(window.location.pathname);
    
    return (
        <section className="slideSection">
            <div className="slideImages">
              
                <Carousel>
                    {imageData.map((image) => {
                        return (
                            <CarouselItem key={image.id}>
                                <div>
                                  {image.pic}
                                </div>
                            </CarouselItem>
                        );
                    })}
                </Carousel>
                
            </div>
        </section>
    );
}

export default Slides;
