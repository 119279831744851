import { Outlet } from 'react-router-dom';
import StyledNavbar from '../components/StyledNavbar';
import Sidebar from "../components/Sidebar";


const Home = () => {
  return (
    <div className="container">

        <div className="flex-grid-1">

            <aside className="topBar">

              <div id="stickyTop">
                <StyledNavbar />
              </div>

            </aside>

            <div className="sansTopBarSpace"></div>

            <div className="flex-grid-2">

                <aside className="col sidebar">
                  <Sidebar/>
                </aside>

                <section className="col main">
                  <Outlet/>
                </section>
                
            </div>

        </div>
          
    </div>
  );
};
export default Home;