import React from "react";
import "../App.css";
import {SidebarData} from "./SidebarData";
import {Logo} from "./Logo";
import { Link } from 'react-router-dom';
import {useLocation} from 'react-router-dom';

function Sidebar() {

    let location = useLocation();

  return (
    <div className="Sidebar">
        <ul className="SidebarList">
            {Logo.map((val, key)=> {
                return (
                    <li
                        key={key}
                        className="logoSidebar"
                        onClick={()=>{
                            window.location.pathname = val.link;
                        }}
                    >
                        <div>
                        <Link to={val.link}>
                        {val.icon}
                        </Link>
                        </div>
                    </li>
                )
            })}
            
        </ul>
        <ul className="SidebarList">
            {SidebarData.map((val, key)=> {
                return (
                <li 
                    key={key} 
                    className="row"
                    id={location.pathname === val.link ? "active" : ""}
                    onClick={()=> {
                        window.location.pathname = val.link;
                    }}
                > 
                    <div id="iconSpace">{val.icon}
                    </div>
                </li>
                );
            })}
        </ul>
    </div>
  );
}

export default Sidebar;