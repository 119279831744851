import React from 'react'

import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';

export const CarouselIcons = [
    {
        title: "arrowBack",
        icon: <ArrowBackIosOutlinedIcon id="carouselIcon"/>
    },
    {
        title: "arrowForward",
        icon: <ArrowForwardIosOutlinedIcon id="carouselIcon"/>
    },
    {
        title: "dot",
        icon: <FiberManualRecordOutlinedIcon id="carouselIconDot"/>
    }
]