import {SidebarData} from "./SidebarData";
import {useLocation} from 'react-router-dom';

const Navbar = () => {

    let location = useLocation();

    return (
        <div id="centeredmenu">

            <ul className="">
                {SidebarData.map((val, key)=> {
                    return (
                    <li 
                        key={key} 
            
                        className={location.pathname === val.link ? "activeTop" : ""}
                        onClick={()=> {
                            window.location.pathname = val.link;
                        }}
                    > 
                        <div id="topIcon">{val.icon}
                        </div>
                    </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default Navbar;