import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import {CarouselIcons} from "./CarouselIcons";

import "../App.css";

export const CarouselItem = ({ children, width }) => {
    return (
        <div className="carousel-item" style={{ width: width }}>
            {children}
        </div>
    );
};

const Carousel = ({ children }) => {

    var slide = 0;
    var urlText = window.location.href;
    //console.log(urlText);
    //console.log(window.location.pathname);

    if (urlText.includes("#")===false) {
        slide = 0;
        //console.log(slide);
    }
    else {
        slide = urlText.substring(urlText.lastIndexOf('#'));
        //console.log(slide);

        var removeHash = slide.replace('#','');
        slide = removeHash;
        slide = parseInt(slide);
        //console.log(slide);
    }

    const [activeIndex, setActiveIndex] = useState(slide);
    const [paused, setPaused] = useState(false);

    //console.log(activeIndex);

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = React.Children.count(children)-1;
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        } 
        setActiveIndex(newIndex);
    };


    useEffect(() => {
        const interval = setInterval(() => {
            if (!paused) {
                updateIndex(activeIndex + 1);
            }
        }, 5000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    });


    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1)
    });

    return (
        <div 
            {...handlers}
            className="carousel"
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
        >
            
            <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, {width: "100%"});
                })}
            </div>

            <div id="carouselBottom"></div>
        
            <div className="indicators">
                <div 
                    id="carouselButton"
                    onClick={() => {
                        updateIndex(activeIndex - 1);
                        }}
                >
                    {CarouselIcons[0].icon}
                </div>

                <div 
                    id="carouselButton"
                    onClick={() => {
                        updateIndex(activeIndex + 1);
                        }}
                >
                    {CarouselIcons[1].icon}
                </div>
            </div>
        </div>
    );
};

export default Carousel;