import React from "react";
import "../App.css";
import { Link } from 'react-router-dom';

import imageData from "../imageData.js";

function Scroll() {

  var getBody= document.getElementsByTagName("body")[0];
  getBody.style = "overflow: visible";
  
  return (
    <section className="section">

        <div className='images'>
          
            {imageData.map((image) => {
                return (
                  <article 
                    key={image.id}
                  >
                      <Link to={image.link}>{image.pic}</Link>
                      <div className="artCaption">
                          <p>{image.artist}</p>

                          <p className="artTitleYear"><span id="artTitle">{image.title}</span><span id="artYear">{image.year}</span></p>
                                                   
                          <p>{image.medium}</p>
                          <p>{image.dims}</p>
                      </div>
                  </article>
                );
            })}
        </div>

    </section>
  );
}

export default Scroll;