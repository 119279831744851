import FlyerImage from '../images/flyerImage.jpg';
import {Email} from "../components/Email";


const About = () => {
    return (
      <section className='section'>

        <div className="current">

            <div id="currentSpace5"></div>

            <img id="emailImage1" src={FlyerImage} alt="flyer"/>

            <div id="homeText">

                <div id = "currentSpace3"></div>

                <div id="info">
                    <p id= "homeNames">
                    Radical Patience
                    </p>

                    <div id="currentSpace2"></div>
                    
                    <p id="homeOther">
                    5442 Monte Vista St.
                    </p>

                    <p id="homeOther">
                    Los Angeles, CA 90042
                    </p>

                    <div id="currentSpace2"></div>

                    <p id="homeOther">
                    Open by appointment
                    </p>

                    <p id= "homeEmail">
                    radicalpatiencelosangeles@gmail.com
                    </p>
                </div>

                <div id = "currentSpace3"></div>

            </div>
        </div>

        <div className="emailOuterDiv">
        
            <div className="emailInnerDiv">
                <a href="mailto:radicalpatiencelosangeles@gmail.com?subject=Schedule Viewing&body=Hello,%0D%0DCan I schedule a viewing for [date / timeframe] ?%0D%0DThanks,%0D[name]">
                    {Email[0].icon}
                </a>
            </div>
        </div>

        <div id = "currentSpace2"></div>

      </section>
  );
};
export default About;