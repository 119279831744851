import React from 'react'

import { ReactComponent as LogoTop } from '../images/radical_patience_logo.svg';

export const Logo = [
    {
        title: "logo",
        icon: <LogoTop id="logo"/>,
        link: "/about"
    }
]